import RestClient from "RestClient";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const restClient = new RestClient();

export const getUserDocumentList = async (id, payload) => {
    const result = await restClient.get(`${BASE_URL}/documents?user_id=${id}`);
    return result?.data; 
}

export const getAllDocumentList = async (id, payload) => {
    const result = await restClient.get(`${BASE_URL}/documentlists`);
    return result?.data; 
}

export const createDocument = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/documents`, payload);
    return result?.data; 
    // return true;
}

export const updateDocument = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/documents/${id}`, payload);
    return result; 
}

export const getSingleDocument = async (id) => {
    const result = await restClient.get(`${BASE_URL}/documents/${id}`);
    return result?.data; 
}

export const deleteDocument = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/documents/${id}`);
    return result?.data; 
}
