import moment from "moment";
import { SLIDER_MARGIN } from "./Constants";

export const IsNumberFloatKey = (value) => {
    return value.match(/^\d{1,}(\.\d{0,2})?$/) ? true : false;
};

export const IsNumberKey = (value) => {
    return value.match(/^[0-9\b]+$/) ? true : false;
};

export const FormatAmount = (str) => {
    return (str + "").replace(/\b(\d+)((\.\d+)*)\b/g, function (a, b, c) {
        return (
            (b.charAt(0) > 0 && !(c || ".").lastIndexOf(".")
                ? b.replace(/(\d)(?=(\d{3})+$)/g, "$1,")
                : b) + c
        );
    });
};

export const calculateValueInPercent = (
    amount,
    per,
    marginAddSubtractValue
) => {
    // marginAddSubtractValue 0 means subtract from original amount
    // marginAddSubtractValue 1 means subtract from original amount
    if (marginAddSubtractValue === 0)
        return parseInt(amount - (amount / 100) * per);
    else return parseInt(amount + (amount / 100) * per);
};

// function doCalculation() {
//     //set purchase price
//     // setPurchasePrice(downPayment, downPaymentPercent);
//     //To update the value of purchase price slider
//     ppValue = parseInt(purchasePrice);

//     //To update the value of down payment slider
//     amtValue = parseInt(downPayment);

//     var propertyTaxes = (purchasePrice * propertyTax) / 100;

//     //Mortgage Structure calculation
//     var loanPercent = 100 - downPaymentPercent;
//     var firstLoanPercent = loanPercent - secondLoanPercent;
//     var firstLoanAmt = parseFloat(
//         ((purchasePrice * firstLoanPercent) / 100).toFixed(0)
//     );
//     var secondLoanAmt = parseFloat(
//         ((purchasePrice * secondLoanPercent) / 100).toFixed(0)
//     );
//     var firstLoanAmttmp = firstLoanAmt;
//     if (firstLoanAmt >= 1000000) {
//         firstLoanAmttmp = 1000000;
//     }

//     var secondLoanAmt = parseFloat(
//         ((purchasePrice * secondLoanPercent) / 100).toFixed(0)
//     );
//     var secondLoanAmttmp = secondLoanAmt;
//     if (secondLoanAmt >= 100000) {
//         secondLoanAmttmp = 100000;
//     }
//     var first = Math.round(
//         pmt(
//             firstLoanInterestRate / 1200, // Annual interest into months
//             years * 12, // Total months for life of loan
//             firstLoanAmt,
//             0,
//             0
//         ) * -1
//     );

//     var second = Math.round(
//         pmt(
//             secondLoanInterestRate / 1200, // Annual interest into months
//             years * 12, // Total months for life of loan
//             secondLoanAmt,
//             0,
//             0
//         ) * -1
//     );

//     var mortgageTotal = first + second;
//     var mortgagePayment = parseFloat((first + second + HOA).toFixed(0));

//     //Deductible Expenses calculation
//     var interestOnFirst = parseFloat(
//         ((firstLoanAmttmp * firstLoanInterestRate) / 1200).toFixed(0)
//     );
//     var interestOnSecond = parseFloat(
//         ((secondLoanAmttmp * secondLoanInterestRate) / 1200).toFixed(0)
//     );
//     var interestOnFirstprincipal = parseFloat(
//         ((firstLoanAmt * firstLoanInterestRate) / 1200).toFixed(0)
//     );
//     var interestOnSecondprincipal = parseFloat(
//         ((secondLoanAmt * secondLoanInterestRate) / 1200).toFixed(0)
//     );
//     var dedPropertyTax = parseFloat(
//         ((purchasePrice * propertyTax) / 1200).toFixed(0)
//     );
//     var total = parseFloat(
//         (interestOnFirst + interestOnSecond + dedPropertyTax).toFixed(0)
//     );
//     total = parseFloat(total.toFixed(0));

//     var taxBreak = parseFloat(((total * (fed + state)) / 100).toFixed(0));

//     var PrincipalRepaid = parseFloat(
//         (
//             first -
//             interestOnFirstprincipal +
//             second -
//             interestOnSecondprincipal
//         ).toFixed(0)
//     );
//     PrincipalRepaid = parseFloat(PrincipalRepaid.toFixed(0));
//     var netExpense =
//         parseFloat((mortgagePayment - taxBreak - PrincipalRepaid).toFixed(0)) ||
//         "";
//     var proratedMonthlyExpense = parseFloat(
//         ((insurance + homewarranty) / 12 + dedPropertyTax).toFixed(0)
//     );
//     var totalMonthlyExpense =
//         parseFloat((netExpense + proratedMonthlyExpense).toFixed(0)) || "";

//     var totalMonthlyPayment = mortgagePayment;
//     //To store old value in variable
//     dDPorg = downPaymentPercent;
//     pTOrg = propertyTax;
//     hOAOrg = HOA;
//     fTOrg = fed;
//     sTOrg = state;
//     insuranceOrg = insurance;
//     homewarrantyOrg = homewarranty;
//     sLIOrg = secondLoanInterestRate;
// }
// Clear all calculations in case of invalid value

export const getPurchasePrice = (downPayment, downPaymentPercent) => {
    // downPayment = downPayment.replace(/\,/g, "");
    var calPurchasePrice = parseInt(
        (downPayment * 100) / downPaymentPercent
    ).toFixed(0);
    // calPurchasePrice = calPurchasePrice;
    // if (calPurchasePrice === "NaN" || calPurchasePrice === NaN)
    //     calPurchasePrice = "";

    if (calPurchasePrice === "NaN" || isNaN(calPurchasePrice))
    //     calPurchasePrice = "";

    return Number(calPurchasePrice);
};
//For setting Purchase Price
// export const setPurchasePrice = (downPayment, downPaymentPercent) => {
//     var calPurchasePrice = parseInt(
//         (downPayment * 100) / downPaymentPercent
//     ).toFixed(0);
//     calPurchasePrice = FormatAmount(calPurchasePrice);
//     return calPurchasePrice;
// };
//For getting down payment
export const getDownPayment = (purchasePrice, downPaymentPercent) => {
    const downPayment = parseInt((Number(purchasePrice) * Number(downPaymentPercent)) / 100);
    return downPayment;
};

//To calculate Down payment in percent
export const getDownPaymentPercent = (downPayment, downPurchasePrice) => {
    const calDownPaymentPercent = (downPayment * 100) / downPurchasePrice;
    return parseInt(calDownPaymentPercent);
};

export const PMT = (rate_per_period, number_of_payments, present_value, future_value, type) => {
    if(rate_per_period !== 0.0){
        // Interest rate exists
        var q = Math.pow(1 + rate_per_period, number_of_payments);
        return -(rate_per_period * (future_value + (q * present_value))) / ((-1 + q) * (1 + rate_per_period * (type)));

    } else if(number_of_payments !== 0.0){
        // No interest rate, but number of payments exists
        return -(future_value + present_value) / number_of_payments;
    }
    return 0;
}

export const IPMT = (present_value, pmt, rate, per) => {
    var tmp = Math.pow(1 + rate, per);
    return 0 - (present_value * tmp * rate + pmt * (tmp - 1));
};

export const validateEmail = (email) => {
    const re = /^[A-Z0-9]+[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return re.test(email);
};

export const getPropertyTaxes = (taxType, purchasePrice, propertyTax, maxPropertyTax, allowCapProperty = false) => {
    let calculatedPropertyTax =
        Number(purchasePrice) * (Number(propertyTax) / 100);

    if(allowCapProperty) {
        if(calculatedPropertyTax > maxPropertyTax) {
            calculatedPropertyTax = maxPropertyTax;
        }
    }
    if(taxType === "Annual") {
        return parseFloat(calculatedPropertyTax).toFixed(0);
    } else {
        return parseFloat(calculatedPropertyTax/12).toFixed(2);
    }
}

export const replaceCommaInNumber = (number) => {
    return number.replace(",",'');
}

export const SETTINGS_CONSTANT_OBJECT = {
    amortizationYears: 30,
    secondAmortizationYears: 12,
    taxDeductionOnFirst: 750000,
    taxDeductionOnSecond: 0,
    stateTaxRate: 9,
    federalTaxRate: 24,
    propertyTax: 1.25,
    capItemizationPropertyTax: 10000,
    standardDeduction: 25900,
};

export const convertSettingToNumber = (record) => {
    const obj = {
        id: Number(record?.id),
        amortizationYears: Number(record?.amortizationYears),
        secondAmortizationYears: Number(record?.secondAmortizationYears)
            ? Number(record?.secondAmortizationYears)
            : 12,
        taxDeductionOnFirst: Number(record?.taxDeductionOnFirst),
        taxDeductionOnSecond: Number(record?.taxDeductionOnSecond),
        stateTaxRate: Number(record?.stateTaxRate),
        federalTaxRate: Number(record?.federalTaxRate),
        propertyTax: Number(record?.propertyTax),
        capItemizationPropertyTax: Number(record?.capItemizationPropertyTax),
        standardDeduction: Number(record?.standardDeduction),
        userId: Number(record?.userId),
    };
    return obj;
}

export const setSessionUserSettings = (userSettings) => {
    sessionStorage.setItem('userSettings',JSON.stringify(userSettings));
}

export const getSessionUserSettings = () => {
    return JSON.parse(sessionStorage.getItem('userSettings'));
}

export const setSessionUserInformation = (userInfo) => {
    sessionStorage.setItem('user',JSON.stringify(userInfo));
}

export const setSessionBrokerInformation = (userInfo) => {
    sessionStorage.setItem('brokerInfo',JSON.stringify(userInfo));
}

export const setSessionAgentInformation = (userInfo) => {
    sessionStorage.setItem('agentInfo',JSON.stringify(userInfo));
}

export const getSessionAgentInformation  = () => {
    return JSON.parse(sessionStorage.getItem('agentInfo'));
}

export const getSessionBrokerInformation  = () => {
    return JSON.parse(sessionStorage.getItem('brokerInfo'));
}

export const getSessionUserInformation = () => {
    return JSON.parse(sessionStorage.getItem('user'));
}

export const setSessionClientSettings = (clientSettings) => {
    sessionStorage.setItem('clientSettings',JSON.stringify(clientSettings));
}

export const getSessionClientSettings = () => {
    return JSON.parse(sessionStorage.getItem('clientSettings'));
}

export const setSessionClientInformation = (clientInfo) => {
    sessionStorage.setItem('clientInfo',JSON.stringify(clientInfo));
}

export const getSessionClientInformation = () => {
    return JSON.parse(sessionStorage.getItem('clientInfo'));
}

export const convertStringToNumberObject = (object) => {
    
    const entries =  Object.keys(object).map(key => {
        return isNaN(object[key]) ? [key, object[key]] :  [key, Number(object[key])];

    });
    return Object.fromEntries(new Map(entries));
}

export const getMinMaxValueSlider = (value) => {
    let ppMin = calculateValueInPercent(value, SLIDER_MARGIN, 0);
    let ppMax = calculateValueInPercent(value, SLIDER_MARGIN, 1);
    return { ppMin, ppMax };
};

export const handleFetchedLeftValues = (searchParams, sessionValues) => {
    let getParameters = {};
    let monthlyMortgageStrucuture = {};
    if (!!sessionValues?.rent === false) {
        if (searchParams.get("rent")) {
            getParameters.rent = replaceCommaInNumber(
                searchParams.get("rent")
            );
        }
        if (searchParams.get("downPayment")) {
            getParameters.downPayment = Number(
                searchParams.get("downPayment")
            );
            const minMaxValue = getMinMaxValueSlider(getParameters.downPayment);
            getParameters.minDownPayment = minMaxValue?.ppMin;
            getParameters.maxDownPayment = minMaxValue?.ppMax;
        }
        if (searchParams.get("downPaymentPercent")) {
            getParameters.downPaymentPercent = Number(
                searchParams.get("downPaymentPercent")
            );
            getParameters.minDownPaymentPercent = 3.5;
            getParameters.maxDownPaymentPercent = 100;
            getParameters.firstLoanPercent =
                100 - getParameters.downPaymentPercent;
        }
        if (searchParams.get("purchasePrice")) {
            getParameters.purchasePrice = Number(
                searchParams.get("purchasePrice")
            );

            const minMaxValue = getMinMaxValueSlider(getParameters.purchasePrice);

            getParameters.minPurchasePrice = minMaxValue?.ppMin;
            getParameters.maxPurchasePrice = minMaxValue?.ppMax;
            monthlyMortgageStrucuture.firstLoanPrincipal =
                getParameters.purchasePrice - getParameters.downPayment;
            // monthlyExpensesValues.firstLoanAmount = parseFloat()
        }
        getParameters.secondLoanInterestRate = 0;
        getParameters.secondLoanPercent = 0;
        // getParameters.firstLoanInterestRate = FIRST_LOAN_RATE;
    } else {
        if (!!sessionValues.rent) {
            getParameters.rent = sessionValues.rent;
        }
        if (!!sessionValues.downPayment) {
            getParameters.downPayment = Number(sessionValues.downPayment);
            const minMaxValue = getMinMaxValueSlider(getParameters.downPayment);
            getParameters.minDownPayment = minMaxValue?.ppMin;
            getParameters.maxDownPayment = minMaxValue?.ppMax;
        }
        if (!!sessionValues.downPaymentPercent) {
            getParameters.downPaymentPercent = Number(
                sessionValues.downPaymentPercent
            );
            getParameters.minDownPaymentPercent = 3.5;
            getParameters.maxDownPaymentPercent = 100;
            getParameters.firstLoanPercent =
                100 - getParameters.downPaymentPercent;
        }
        if (!!sessionValues.purchasePrice) {
            getParameters.purchasePrice = Number(
                sessionValues.purchasePrice
            );

            const minMaxValue = getMinMaxValueSlider(getParameters.purchasePrice);

            getParameters.minPurchasePrice = minMaxValue?.ppMin;
            getParameters.maxPurchasePrice = minMaxValue?.ppMax;
            monthlyMortgageStrucuture.firstLoanPrincipal =
                getParameters.purchasePrice - getParameters.downPayment;
            // monthlyExpensesValues.firstLoanAmount = parseFloat()
        }
        getParameters.secondLoanInterestRate = 0;
        getParameters.secondLoanPercent = 0;
        // getParameters.firstLoanInterestRate = FIRST_LOAN_RATE;
    }
    getParameters.zipCode = sessionValues?.zipcode;
    return getParameters;
}

export  const calcDebtPaymentsCalculations = (newValues) => {
    let {
        selfAutoLoan,
        selfStudentLoan,
        selfCreditCards,
        selfMortgage,
        selfOther,
        spouseAutoLoan,
        spouseStudentLoan,
        spouseCreditCards,
        spouseMortgage,
        spouseOther,
    } = newValues;
    // Addition of Self Debt Payments
    newValues.selfTotalDebtPayments =
    selfAutoLoan + selfStudentLoan + selfCreditCards + selfMortgage + selfOther;

    // //Addition of Spouse Debt Payments
    newValues.spouseTotalDebtPayments =
        spouseAutoLoan +
        spouseStudentLoan +
        spouseCreditCards +
        spouseMortgage +
        spouseOther;

    // // Addition of Self and Spouse Debt Payments

    newValues.totalDebtPayments =
        newValues.selfTotalDebtPayments + newValues.spouseTotalDebtPayments;
    return newValues;
} 

export const statusList = () => {
    return [
        {
            status: "Active",
        },
        {
            status: "Inactive",
        },
    ];
};



export const getActualPropertyTax = (purchasePrice, propertyTax) => {
    let calculatedPropertyTax =
        purchasePrice * (propertyTax / 100);
    if(calculatedPropertyTax <= 0) {
        return 0;
    } else {
        return parseInt(calculatedPropertyTax);
    }
}

export const getRentOwnedEstateRecords = (userRecord,expensesRecord,userSettings) => {
    let reportData = [];
    let rentData = [];
    let annualData = [];
    let labels = [];
    let totalEquityList = [];
    let appreciationList = [];
    let totalHomeValue = [];

    const rentalIncrease = 10;
    const inflationRate = 3;
    const increasePropertyTax = 2;
    const averageAppreciation = 7;

    if (userRecord && expensesRecord && userSettings) {
        const pTax = getPropertyTaxes(
            "Annual",
            Number(userRecord?.purchasePrice),
            Number(userSettings?.propertyTax),
            Number(userSettings?.capItemizationPropertyTax)
        );
        const currentYear = Number(moment().format("YYYY"));
        const maxYear = currentYear + 30;
        for (let i = currentYear; i <= maxYear; i++) {
            let obj = {};
            obj.year = i;
            if (i === currentYear) {
                obj.rent = Number(userRecord?.rent).toFixed(0);
                obj.annualRent = Number(userRecord?.rent) * 12;
                obj.mortgage = Number(
                    expensesRecord?.mortgageExpenseRecord?.totalExpense
                );
                obj.propertyTax =
                    Number(pTax) +
                    Number(
                        expensesRecord?.taxExpenseRecord
                            ?.nonDeductiblePropertyTax
                    ) *
                        12;
                obj.expenses = (
                    (Number(expensesRecord?.annualExpenseRecord?.insurance) +
                        Number(
                            expensesRecord?.annualExpenseRecord?.homeWarranty
                        )) /
                        12 +
                    Number(expensesRecord?.mortgageExpenseRecord?.hoa)
                ).toFixed(0);
                obj.annualExpense =
                    Number(obj.mortgage) * 12 +
                    Number(obj.propertyTax) +
                    Number(obj.expenses) * 12;
                obj.mid = Number(
                    expensesRecord?.taxExpenseRecord?.annualTaxBreak
                );
                obj.nett = obj.annualExpense - obj.mid;
                obj.appreciation =
                    Number(userRecord?.purchasePrice) *
                    (averageAppreciation / 100);
                obj.beforeTotalEquity =  Number(obj.appreciation) - Number(obj.nett);
                obj.totalEquity = Number(obj.annualRent) + Number(obj.beforeTotalEquity);
                obj.totalHomeValue = Number(obj.appreciation) + Number(userRecord?.purchasePrice);
                reportData.push(obj);
                rentData.push(Number(obj.annualRent));
                annualData.push(obj.annualExpense);
                // labels.push(Number(obj.annualRent));
                appreciationList.push(Number(obj.appreciation));
                totalEquityList.push(Number(obj.totalEquity));
                totalHomeValue.push(Number(obj.totalHomeValue))
                labels.push(Number(i));
            } else {
                let oldRecord = reportData.find((rd) => rd.year === i - 1);
                
                obj.rent = (
                    Number(oldRecord?.rent) +
                    Number(oldRecord?.rent) * (rentalIncrease / 100)
                ).toFixed(0);
                obj.annualRent = (obj.rent * 12).toFixed(0);
                obj.mortgage = Number(
                    expensesRecord?.mortgageExpenseRecord?.totalExpense
                );
                obj.propertyTax = parseInt(
                    Number(oldRecord.propertyTax) *
                        (1 + increasePropertyTax / 100)
                );
                obj.expenses = (
                    Number(oldRecord.expenses) *
                    (1 + inflationRate / 100)
                ).toFixed();
                obj.annualExpense =
                    Number(obj.mortgage) * 12 +
                    Number(obj.propertyTax) +
                    Number(obj.expenses) * 12;

                obj.mid = Number(
                    expensesRecord?.taxExpenseRecord?.annualTaxBreak
                );
                obj.nett = obj.annualExpense - obj.mid;
                obj.appreciation = (
                    Number(oldRecord?.appreciation) +
                    Number(oldRecord?.appreciation) *
                        (averageAppreciation / 100)
                ).toFixed();
                obj.beforeTotalEquity =  Number(oldRecord?.appreciation) + Number(obj.appreciation) - Number(obj.nett);
                obj.totalEquity = Number(obj.annualRent) + Number(obj.beforeTotalEquity);
                obj.totalHomeValue = Number(oldRecord?.totalHomeValue) + Number(obj.appreciation);

                reportData.push(obj);
                rentData.push(Number(obj.annualRent));
                annualData.push(obj.annualExpense);
                appreciationList.push(Number(obj.appreciation));
                totalEquityList.push(Number(obj.totalEquity));
                totalHomeValue.push(Number(obj.totalHomeValue))
                // labels.push(Number(obj.annualRent));
                labels.push(Number(i));
            }
        }

        return { reportData, rentData, annualData, labels, totalEquityList, appreciationList, totalHomeValue };
    }
}


export const getConvertedFileName = (fileName) => {
    if (fileName) {
        const splitFileName = fileName.replace(/\s/g, "-").toLowerCase().split(".");
        const newSplitFileName = `${splitFileName[0]}${Date.now()}.${
            splitFileName[splitFileName.length - 1]
        }`;
        return newSplitFileName
    }
}

export const getDirectIncomeCalculations = (payload) => {
    let newDirectIncome = {
        selfGrossPreTaxSalary: payload?.selfIncome
            ? (Number(payload?.selfIncome) / 12).toFixed()
            : 0,
        spouseGrossPreTaxSalary: payload?.spouseIncome
            ? (Number(payload?.spouseIncome) / 12).toFixed()
            : 0,
        selfBonus: payload?.others
            ? (Number(payload?.others) / 12).toFixed()
            : 0,
        spouseBonus: 0,
        selfCommissions: 0,
        spouseCommissions: 0,
        spouseOverTime: 0,
        selfOverTime: 0,
    };

    newDirectIncome.selfDirectIncome =
        Number(newDirectIncome.selfGrossPreTaxSalary) +
        Number(newDirectIncome.selfBonus);
    newDirectIncome.spouseDirectIncome =
            Number(newDirectIncome.spouseGrossPreTaxSalary) +
            Number(newDirectIncome.spouseBonus);
    newDirectIncome.totalDirectIncome =
        Number(newDirectIncome.selfDirectIncome) +
        Number(newDirectIncome.spouseDirectIncome); 

        return newDirectIncome;
}

export const iterateRatios = (
    purchasePrice,
    totalMonthly,
    annualExpenseValues,
    directIncome,
    indirectIncome,
    debtPayments,
    propertyTax
) => {
    let frontRatio = 0;
    let backRatio = 0;
    // frontRatio = totalMortgagePayment + acutalpropertyTax/12 + homeInsurace/12
    const commonAddition =
        Number(totalMonthly) +
        Number(getActualPropertyTax(purchasePrice,propertyTax) / 12) +
        Number(annualExpenseValues?.insurance / 12);
    const totalGrossIncome =
        Number(directIncome?.totalDirectIncome) +
        Number(indirectIncome?.totalIndirectIncome);

    if (totalGrossIncome > 0 && commonAddition > 0) {
        frontRatio = commonAddition / totalGrossIncome;
    }
    if (commonAddition > 0 && totalGrossIncome > 0) {
        backRatio =
            (commonAddition + Number(debtPayments?.totalDebtPayments)) /
            totalGrossIncome;
    }
    if (frontRatio > 0 && backRatio > 0) {
        // setRatios({
        //     ...ratios,
        //     calculatedFrontRatio: (frontRatio * 100).toFixed(2),
        //     calculatedBackRatio: (backRatio * 100).toFixed(2),
        // });
        return { currentBackRatio: (backRatio * 100).toFixed(2) };
    }
};

export const getProfileCompletionPercentage = (values) => {
    let profilePercentage = 0;
    profilePercentage += values?.documentUploaded;
    profilePercentage += values?.profileImageUploaded;
    profilePercentage += values?.qualificationsUploaded;
    profilePercentage += values?.experiencesUploaded;
    return profilePercentage;
}

export const getDateObject = (date, dateFormat = null) => {
    return date ? new Date((dateFormat ? moment(date, dateFormat): moment(date)).format("YYYY-MM-DD")): null;
}