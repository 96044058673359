import React, { lazy } from "react";
import Loadable from "../../components/Loadable/Loadable";

const QRVerification= Loadable(lazy(() => import("./QRVerification")));
// const AddClient= Loadable(lazy(() => import("./AddClient/AddClient")));
// const NewClients= Loadable(lazy(() => import("./NewClients/NewClients")));

// const HomePurchaseRentStep2 = Loadable(lazy(() => import("./HomePurchaseStep2")));
// const HomePurchaseRentStep3 = Loadable(lazy(() => import("./HomePurchaseStep3/HomePurchaseStep3")));

const qrRoutes = [
    {
        path: "/doVerification/verifyQR",
        element: <QRVerification />,
    },
    
];

export default qrRoutes;
