
import { getSessionUserInformation } from "app/utils/utility.service";
import { Menu } from "primereact/menu";
import { Fragment, useRef} from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./Partials.scss";
import { Card } from "primereact/card";
import { Avatar } from 'primereact/avatar';

const Header = ({handleSidebar}) => {
    const menu = useRef(null);
    const sessionValues = getSessionUserInformation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    let items = [
        // {
        //     label: "Home",
        //     command: () => {
        //         window.location.hash = `/`;
        //     },
        // },
        // {
        //     label: "My Applications",
        //     command: () => {
        //         window.location.hash = `/my-applications`;
        //     },
        // },
        // {
        //     label: "My Profile",
        //     command: () => {
        //         window.location.hash = `/my-profile`;
        //     },
        // },
        // {
        //     label: "My Documents",
        //     command: () => {
        //         window.location.hash = `/my-documents`;
        //     },
        // },
        // {
        //     label: "My Qualifcation",
        //     command: () => {
        //         window.location.hash = `/my-qualification`;
        //     },
        // },
        // {
        //     label: "My Experience",
        //     command: () => {
        //         window.location.hash = `/my-experience`;
        //     },
        // },
        {
            label: "Logout",
            command: () => {
                sessionStorage.clear();
                navigate("/");
            },
        },
    ];

   
    const valueTemplate = (value) => {
        return (
            <Fragment>
                <span style={{color:"#fff"}}><b>{value}% Profile Completed</b></span>
            </Fragment>
        );
    };


    return (
        <div className="container-fluid">

            <div className="card">
                <Card className="header-card">
                    <div className="Header">

                        <div className="left">
                            <div>
                                <img
                                    src="/nrblogo-removebg.png"
                                    alt="Naturopathy Registration Board (NRB)"
                                    title="Naturopathy Registration Board (NRB)"
                                />
                            </div>
                            
                        </div>
                        <div className="main">
                            <div className="logo_title">
                                <h1 className="mt-0 text-center">
                                    NATUROPATHY REGISTRATION BOARD (NRB)
                                </h1>
                                <h4 className="mb-1">
                                    <b>under the aegis of National Institute of Naturopathy (NIN)</b>
                                </h4>
                                <h5 className="mt-0 text-center">
                                    Ministry of Ayush, Government of India
                                </h5>
                            </div>
                        </div>
                        {!!sessionValues?.firstname ? (
                            <Fragment>
                                <div className="right">

                                    {/* <h4 className="mr-3"><Link to={`/`}>Home</Link></h4> */}
                                    <h4>Hi {sessionValues?.firstname}</h4>
                                    <Avatar icon="pi pi-user" className="mr-4 ml-4" size="xlarge" style={{ backgroundColor: '#2196F3', color: '#ffffff' }}  shape="circle"  onClick={(event) =>
                                            menu.current.toggle(event)
                                        } />

                                    {/* <h4><i className="pi pi-bars" onClick={handleSidebar} style={{ fontSize: '2rem', cursor:"pointer" }}></i></h4> */}

                                    <Menu
                                        model={items}
                                        popup
                                        ref={menu}
                                        id="popup_menu"
                                    />
                                    {/* <i
                                        className="pi pi-bars ml-2"
                                        style={{
                                            fontSize: "1em",
                                            cursor: "pointer",
                                        }}
                                       
                                        aria-controls="popup_menu"
                                        aria-haspopup
                                    ></i> */}
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className="right">
                                    {/* <div>
                                        
                                            <h4 >
                                               Ministry of Ayush, Govt. Of India
                                            </h4>
                                    </div> */}
                                    <div>
                                        <img
                                            src="/ministry_of_ayush-removebg.png"
                                            alt="राष्ट्रीय प्राकृतिक चिकित्सा संस्थान, आयुष मंत्रालय, भारत सरकार"
                                            title="राष्ट्रीय प्राकृतिक चिकित्सा संस्थान, आयुष मंत्रालय, भारत सरकार"
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </Card>
                {/* <div className="mt-5">
                <ProgressBar value={getProfileCompletionPercentage(sessionValues)} displayValueTemplate={valueTemplate} />
                </div> */}
            </div>
        </div>
    );
};

export default Header;
