import { useState } from "react";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import SuspenseFallback from "../SuspenseFallback/SuspenseFallback";
import { Outlet, useLocation } from "react-router-dom";


const DefaultLayout = (props) => {

    const [visible, setVisible] = useState(false);

    const handleSidebar = () => {
        setVisible(!visible);
    }

    const renderHeader = () => {
            
        return <Header handleSidebar={handleSidebar} />
    }

    // let items = [
    //     {
    //         label: "Home",
    //         command: () => {
    //             window.location.hash = `/`;
    //         },
    //     },
    //     {
    //         label: "My Applications",
    //         command: () => {
    //             window.location.hash = `/my-applications`;
    //         },
    //     },
    //     {
    //         label: "My Profile",
    //         command: () => {
    //             window.location.hash = `/my-profile`;
    //         },
    //     },
    //     {
    //         label: "My Documents",
    //         command: () => {
    //             window.location.hash = `/my-documents`;
    //         },
    //     },
    //     {
    //         label: "My Qualifcation",
    //         command: () => {
    //             window.location.hash = `/my-qualification`;
    //         },
    //     },
    //     {
    //         label: "My Experience",
    //         command: () => {
    //             window.location.hash = `/my-experience`;
    //         },
    //     },
    //     {
    //         label: "Logout",
    //         command: () => {
    //             // dispatch(clearHomePurchaseValues());
    //             sessionStorage.clear();
    //             // navigate("/");
    //         },
    //     },
    // ];

    return (
        <SuspenseFallback>
            <div className="App-container">
                {/* <Sidebar visible={visible} onHide={() => setVisible(false)} position="right" >
                <MegaMenu model={items} orientation="vertical" breakpoint="767px" />

                </Sidebar> */}
                <div className="outer-container">
                    <header className="mb-2 mt-2">
                        {renderHeader()}
                    </header>
                    <main className="container-fluid">
                        <div className={useLocation().pathname.indexOf('reports') == -1 && 'child-outlet'} >
                            <Outlet />
                        </div>
                    </main>
                    <footer>
                        <Footer />
                    </footer>
                </div>
            </div>
        </SuspenseFallback>
    );
};

export default DefaultLayout;
