import { Loading } from "app/components";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Fragment, useEffect, useRef, useState } from "react";
import { createDocument, updateDocument } from "./my-document.service";
import { getConvertedFileName } from "app/utils/utility.service";
import { Dropdown } from "primereact/dropdown";
import { PHOTOGRAPH_DIMENSION } from "app/utils/Constants";
import { SIGNATURE_DIMENSION } from "app/utils/Constants";

const MyDocument = ({
    documentModal,
    handleDocumentModal,
    sessionValues,
    rowData,
}) => {
    const [documentValues, setDocumentValues] = useState({});
    const [documentList, setDocumentList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [currentDocument, setCurrentDocument] = useState(null);

    const fileUploadRef = useRef(null);

    const handleModalInputChange = (e) => {
        const { name, value } = e.target;
        setDocumentValues({ ...documentValues, [name]: value });
    };

    const validateInput = () => {
        if (!rowData?.id) {
            if (!documentValues?.files?.name) {
                return false;
            }
        }
        // if (!values?.dateOfBirthImage?.name) {
        //     return false;
        // }
        // if (!values?.addressProofImage?.name) {
        //     return false;
        // }
        // if (!values?.aadharProof?.name) {
        //     return false;
        // }
        return true;
    };

    const handleDocumentSave = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        if (!validateInput()) {
            return false;
        }
        setIsLoading(true);
        let formData = new FormData();

        if (!!documentValues.files) {
            const convertedFileName = getConvertedFileName(
                documentValues?.fileName
            );
            formData.append("fileName", `${convertedFileName}`);
            formData.append(
                "file",
                documentValues?.files,
                `${convertedFileName}`
            );
        }
        // for (const key of Object.keys(rowData)) {
        //     formData.append(key, `${documentValues[key]}`);
        // }
        if (!!rowData?.id) {
            const record = await updateDocument(rowData?.id, formData);
            if (record.status === false) {
                setIsLoading(false);
                alert(record?.message);
                handleDocumentModal();
            } else {
                setIsLoading(false);
                alert("File uploaded successfully");
                // insert settings value
                handleDocumentModal();
            }
        } else {
            formData.append("user_id", sessionValues.id);
            const record = await createDocument(formData);
            if (record.status === false) {
                setIsLoading(false);
                alert(record?.message);
            } else {
                setIsLoading(false);
                alert("Record created successfully");
                // insert settings value
                handleDocumentModal();
            }
        }
    };

    const myUploader = (event, name) => {
        const size = convertMBToBytes(Number(rowData?.fileSize));
        if (size <= event.files[0].size) {
            alert(
                "You cannot upload file size more than " +
                    rowData?.fileSize +
                    "MB"
            );
            fileUploadRef?.current.clear();
            return;
        }
        if (
            rowData?.documentName.includes(
                "Photograph with white background"
            ) ||
            rowData?.documentName.includes("Specimen Signature")
        ) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    if (
                        rowData?.documentName.includes(
                            "Photograph with white background"
                        )
                    ) {
                        console.log("img", img.height, img.width);
                        if (
                            img.height !== PHOTOGRAPH_DIMENSION.height &&
                            img.width !== PHOTOGRAPH_DIMENSION.width
                        ) {
                            window.alert(
                                `Photograph dimensions must be of width ${PHOTOGRAPH_DIMENSION.width} and height ${PHOTOGRAPH_DIMENSION.height}`
                            );

                            fileUploadRef?.current.clear();
                            return;
                        }
                        setDocumentValues({
                            ...documentValues,
                            [name]: event.files[0],
                            [`fileName`]: event.files[0].name,
                        });
                    } else if (
                        rowData?.documentName.includes("Specimen Signature")
                    ) {
                        if (
                            img.height !== SIGNATURE_DIMENSION.height &&
                            img.width !== SIGNATURE_DIMENSION.width
                        ) {
                            window.alert(
                                `Photograph dimensions must be of width ${SIGNATURE_DIMENSION.width} and height ${SIGNATURE_DIMENSION.height}`
                            );

                            fileUploadRef?.current.clear();
                            return;
                        }
                        setDocumentValues({
                            ...documentValues,
                            [name]: event.files[0],
                            [`fileName`]: event.files[0].name,
                        });
                    } else {
                        setDocumentValues({
                            ...documentValues,
                            [name]: event.files[0],
                            [`fileName`]: event.files[0].name,
                        });
                    }
                    // setImageDimensions({ width: img.width, height: img.height });
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(event.files[0]);
            return;
        } else {
            setDocumentValues({
                ...documentValues,
                [name]: event.files[0],
                [`fileName`]: event.files[0].name,
            });
        }
    };

    const onHide = () => {
        handleDocumentModal(false);
    };
    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="col-3"></div>
                    <div className="col-7">
                        {/* <Button
                            label="Cancel"
                            className="p-button-raised p-button-lg"
                            onClick={() => onHide()}
                        /> */}
                        <Button
                            label="Save"
                            className="p-button-raised p-button-warning p-button-lg"
                            onClick={handleDocumentSave}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const convertMBToBytes = (mb) => {
        const bytes = mb * (1024 * 1024);
        return bytes;
    };

    const enterRequiredField = () => (
        <div className="p-error text-left">Please enter required field.</div>
    );

    const imageFormats = ".jpg, .jpeg, .png, .JPG, .JPEG, .PNG";

    const renderDocumentLabel = (rowData) => {
        if (
            rowData?.documentName.includes("Photograph with white background")
        ) {
            return (
                <Fragment>
                    <label className="mt-1">
                        {`Upload Document* (${PHOTOGRAPH_DIMENSION?.width}px*${PHOTOGRAPH_DIMENSION?.height}px)`}
                    </label>
                    <label className="mt-1">
                        {`(Max Size: ${rowData?.fileSize}MB)`}
                    </label>
                </Fragment>
            );
        } else if (rowData?.documentName.includes("Specimen Signature")) {
            return (
                <Fragment>
                    <label className="mt-1">
                        {`Upload Document* (${SIGNATURE_DIMENSION?.width}px*${SIGNATURE_DIMENSION?.height}px)`}
                    </label>
                    <label className="mt-1">
                        {`(Max Size: ${rowData?.fileSize}MB)`}
                    </label>
                </Fragment>
            );
        } else {
            return (
                <label className="mt-1">
                    Upload Document* (Max {rowData?.fileSize}MB){" "}
                </label>
            );
        }
    };

    return (
        <Dialog
            header={!!rowData?.id ? "Update Document" : "Add Document"}
            visible={documentModal}
            onHide={() => onHide()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "40vw" }}
            footer={renderFooter()}
            className="AnnualExpenseModal"
            position="top"
            closeOnEscape={false}
            draggable={false}
        >
            <div className="text-right mt-4">
                {/* Examination Passed */}
                {isLoading && <Loading />}

                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">Document Name* </label>
                    </div>
                    <div className="col-5">
                        <div className="field text-left">
                            <label className="ml-4 mt-3">
                                <strong>{rowData?.documentName}</strong>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-3">{renderDocumentLabel(rowData)}</div>
                    <div className="col-5">
                        <div className="field text-left ml-4">
                            <FileUpload
                                ref={fileUploadRef}
                                name="profileImage"
                                className="fileUpload"
                                mode="basic"
                                customUpload
                                uploadHandler={(event) =>
                                    myUploader(event, "files")
                                }
                                // onUpload={(event) =>
                                //     myUploader(event, "profileImage")
                                // }
                                auto
                                chooseLabel={`Choose ${rowData?.fileType} file`}
                                accept={
                                    rowData?.fileType === "PDF"
                                        ? ".pdf"
                                        : imageFormats
                                }
                                // maxFileSize={convertMBToBytes(Number(rowData?.fileSize))}
                            />
                            {formSubmitted &&
                                !documentValues?.files?.name &&
                                enterRequiredField()}
                            <label className="ml-4 mt-2">
                                <strong>
                                    {!!documentValues?.fileName
                                        ? documentValues?.fileName
                                        : rowData?.fileName}
                                </strong>{" "}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default MyDocument;
