import { Loading } from "app/components";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { bodyTemplate } from "app/utils/datatable.service";
import {
    deleteDocument,
    getAllDocumentList,
    getUserDocumentList,
} from "./my-document.service";
import { getSessionUserInformation } from "app/utils/utility.service";
import MyDocument from "./MyDocument";
import { pdfTemplate } from "app/utils/datatable.service";
import { mandatoryTemplate } from "app/utils/datatable.service";

const MyDocumentList = ({setCheckChanges, checkChanges}) => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [documentModal, setDocumentModal] = useState(false);
    const [list, setList] = useState([]);
    const [listOfDocuments, setListOfDocuments] = useState([]);
    const [allDocumentList, setAllDocumentList] = useState([]);
    const [rowData, setRowData] = useState({});
    const [headerObj, setHeaderObj] = useState({});
    const sessionValues = getSessionUserInformation();

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete?")) {
            const deletedRecord = await deleteDocument(id);
            if (deletedRecord) {
                fetchUserDocumentList();
                alert("Record deleted successfully");
                return;
            } else {
                alert("Something went wrong, please try again later");
                return;
            }
        }
    };

    const handleEdit = async (data) => {
        setRowData(data);
        handleDocumentModal();
    };

    const actionBodyTemplate = (data, props) => {
        return (
            <>
                <Button
                    onClick={() => handleEdit(data)}
                    className="p-button p-button-primary mr-2"
                >
                    Edit
                </Button>
                {/* <Button
                    onClick={() =>
                        handleDelete(
                            data?.id,
                            data?.advertisement_number,
                            data?.start_date,
                            data?.examination_name
                        )
                    }
                    className="p-button p-button-danger"
                >
                    Delete
                </Button> */}
            </>
        );
    };

    const handleApply = async (
        id,
        advertisement_number,
        start_date,
        examination_name
    ) => {
        // Fetch Vacancy List
        // setIsLoading(true);
        // const record = await getVacancies(id);
        // if (record && record.length > 0) {
        //     record.map((li, index) => (li.srNo = index + 1));
        //     setVacancyList(record);
        //     setHeaderObj({
        //         advertisement_number,
        //         start_date,
        //         examination_name,
        //     });
        //     setShowVacancy(true);
        //     setIsLoading(false);
        // } else {
        //     setHeaderObj({});
        //     setShowVacancy(false);
        //     alert("No Vacancy Present");
        //     setIsLoading(false);
        // }
    };

    const fetchUserDocumentList = async () => {
        // setIsLoading(true);

        let list = await getUserDocumentList(sessionValues?.id);
        if (list.length > 0) {
            list.map((li, index) => (li.srNo = index + 1));
            setList(list);
        } else {
            setList([]);
        }
        return;
    };


    const handleDocumentModal = () => {
        if (!documentModal) {
            // fetchUserDocumentList();
        } else {
            setRowData(null);
            fetchUserDocumentList();
        }
        setDocumentModal(!documentModal);
        setCheckChanges(!checkChanges)
    };

    useEffect(() => {
        fetchUserDocumentList();
        // fetchAllDocumentList();
    }, []);

    const renderInputdocumentModal = () => (
        <MyDocument
            documentModal={documentModal}
            handleDocumentModal={handleDocumentModal}
            sessionValues={sessionValues}
            rowData={rowData}
        />
    );

    return (
        <Fragment>
            <div className="card">
                <Card className="mb-4" title="Please upload all documents marked by *asterisk*">
                    <div className="grid table-demo">
                        <Toast ref={toast} className="ToastMessage" />
                        {isLoading && (
                            <div className="spinner inner-spinner">
                                <Loading />
                            </div>
                        )}
                        <div className="col-12 lg:col-12 md:col-12">
                            <div className="card">
                                {/* <Button
                                    onClick={handleDocumentModal}
                                    label="Add New"
                                    className="p-button-raised p-button-warning mb-3"
                                /> */}
                                <DataTable
                                    value={list}
                                    scrollable
                                    scrollHeight="600px"
                                    scrollDirection="both"
                                    className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    rows={10}
                                    dataKey="id"
                                    paginator
                                    rowHover
                                    // globalFilter={globalFilter}
                                    emptyMessage="No list found."
                                    loading={isLoading}
                                    // header={tableHeader}
                                >
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "50px",
                                        }}
                                        field="srNo"
                                        header="Sr. No."
                                        sortable
                                        body={bodyTemplate}
                                    ></Column>
                                    
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="documentName"
                                        header="Name"
                                        sortable
                                        body={mandatoryTemplate}
                                    ></Column>
                                     <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "50px",
                                        }}
                                        field="description"
                                        header="Description"
                                        sortable
                                        body={bodyTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="fileSize"
                                        header="Max File Size Allowed (In MB)"
                                        sortable
                                        body={bodyTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="fileName"
                                        header="File Name"
                                        sortable
                                        body={(data) =>
                                            pdfTemplate(
                                                data,
                                                "File Name",
                                                "fetchDocumentsPDF"
                                            )
                                        }
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="status"
                                        header="Action"
                                        body={actionBodyTemplate}
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                        {!!documentModal &&
                            renderInputdocumentModal()}
                        {/* {displayConfirmation && (
                <ConfirmationDialogBox
                    handleCancelConfirmation={handleCancelConfirmation}
                    handleConfirmation={handleConfirmation}
                    displayConfirmation={displayConfirmation}
                />
            )} */}
                    </div>
                </Card>
            </div>
        </Fragment>
    );
};

export default MyDocumentList;
