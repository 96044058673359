import { getSessionUserInformation } from "app/utils/utility.service";
import { Navigate } from "react-router-dom";
import { DefaultLayout } from "../components";
import sessionRoutes from "../views/sessions/SessionRoutes";
import qrRoutes from "app/views/qrverification/QRVerificationRoutes";

export const AllPages = () => {
    const sessionValues = getSessionUserInformation();
    const all_routes = [
        {
            element: <DefaultLayout />,
            children: [...sessionRoutes, ...qrRoutes],
        },
    ];
    all_routes.push({
        path: "/",
        element: <Navigate to="/session/signin/step1" />,
    });
    return all_routes;
};
